<template>
  <div class="mdl-layout mdl-js-layout mdl-layout--fixed-header">
    <header class="mdl-layout__header">
      <div class="mdl-layout__header-row">
        <!-- Title -->
        <span class="mdl-layout-title">KotonaruSekai</span>
        <!-- Add spacer, to align navigation to the right -->
        <div class="mdl-layout-spacer"></div>
        <!-- Navigation. We hide it in small screens. -->
        <nav class="mdl-navigation mdl-layout--large-screen-only">
          <a class="mdl-navigation__link" href="#" @click="showEpisodes">Episodes </a>
          <a class="mdl-navigation__link" href="#" @click="showAbout">About</a>
        </nav>
      </div>
    </header>
    <div class="mdl-layout__drawer">
      <span class="mdl-layout-title">KotonaruSekai</span>
      <nav class="mdl-navigation">
        <a class="mdl-navigation__link" href="#" @click="showEpisodes">Episodes</a>
        <a class="mdl-navigation__link" href="#" @click="showAbout">About</a>
      </nav>
    </div>
    <main class="mdl-layout__content" style="background-color: black">
      <div class="page-content" >
        <div v-if="show.episodes">
          <episodes/>
        </div>
        <div v-if="show.about">
          <about/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Episodes from "@/components/Episodes";
import About from "@/components/About";

export default {
  name: 'App',
  components: {About, Episodes},
  data() {
    return {
      show: {
        episodes: true,
        about: false,
      }
    };
  },
  methods: {
    showEpisodes() {
      this.showContent('episodes');
    },
    showAbout() {
      this.showContent('about');
    },
    showContent(page) {
      for (const property in this.show) {
        this.show[property] = false;
      }
      this.show[page] = true;
    },
  },
}
</script>
