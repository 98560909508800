<template>
  <div class="content">

  </div>
</template>

<script>
export default {
  name: 'About',
  methods: {}
}
</script>
