<template>
  <div class="content webtoon-episode-wrapper" id="episodesRoot">
    <audio id="episode-audio" :src="currentEpisodeSound" muted></audio>
    <div class="mdl-grid center-items" style="margin-top: 10px">
      <button class="mdl-button mdl-js-button mdl-js-ripple-effect"
              style="color: white"
              :disabled="!previousEnabled"
              @click="previousEpisode">
        <i class="material-icons">arrow_back</i>
      </button>
      <button class="mdl-button mdl-js-button mdl-js-ripple-effect"
              style="color: white"
              :disabled="!nextEnabled"
              @click="nextEpisode">
        <i class="material-icons">arrow_forward</i>
      </button>
    </div>
    <!-- TODO use Episode component -->
    <!--episode :source="currentEpisodeSource" id="episode-img"/-->
    <div class="mdl-grid center-items">
      <div class="mdl-cell">
        <img :src="episodeSource('01')" class="webtoon-episode-img" alt="webtoon-episode">
        <img :src="episodeSource('02')" class="webtoon-episode-img" alt="webtoon-episode">
        <img :src="episodeSource('03')" class="webtoon-episode-img" alt="webtoon-episode">
        <img :src="episodeSource('04')" class="webtoon-episode-img" alt="webtoon-episode">
        <img :src="episodeSource('05')" class="webtoon-episode-img" alt="webtoon-episode">
        <img :src="episodeSource('06')" class="webtoon-episode-img" alt="webtoon-episode">
      </div>
    </div>
    <div class="mdl-grid center-items" style="margin-top: 10px">
      <button class="mdl-button mdl-js-button mdl-js-ripple-effect"
              style="color: white"
              :disabled="!previousEnabled"
              @click="previousEpisode">
        <i class="material-icons">arrow_back</i>
      </button>
      <button class="mdl-button mdl-js-button mdl-js-ripple-effect"
              style="color: white"
              :disabled="!nextEnabled"
              @click="nextEpisode">
        <i class="material-icons">arrow_forward</i>
      </button>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Episodes',
  components: {},
  data() {
    return {
      episode: {
        max: 1,
        root: '/demo',
        current: {
          number: 1,
          sound: 'sample',
        }
      }
    }
  },
  computed: {
    previousEnabled() {
      return this.episode.current.number > 1;
    },
    nextEnabled() {
      return this.episode.current.number < this.episode.max;
    },
    currentEpisodeSource() {
      return `${this.episode.root}/webtoon-${this.episode.current.number}.jpg`;
    },
    currentEpisodeSound() {
      return `${this.episode.root}/audio/${this.episode.current.sound}.mp3`;
    },
  },
  mounted() {
    /*const episodeImg = document.getElementById('episode-img');
    document.onclick = function (e) {
      console.log(e);
    };
    console.log('offset top: ', episodeImg.offsetTop);
    console.log('offset height: ', episodeImg.offsetHeight);
    console.log('coordinates: ', this.getScreenCoordinates(episodeImg));*/
    /*episodeImg.addEventListener('mousemove', e => {
      console.log(e);
    });*/
    //document.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    //document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    episodeSource(number) {
      return `${this.episode.root}/${number}.jpg`;
    },
    previousEpisode() {
      console.log('previousEpisode');
      this.episode.current.number--;
      this.scrollToTop();
    },
    nextEpisode() {
      console.log('nextEpisode');
      this.playAudio();
      this.episode.current.number++;
      this.scrollToTop();
    },
    scrollToTop() {
      console.log('scroll to top');
      document.getElementById('episodesRoot').scrollIntoView();
    },
    playSound(sound) {
      if (sound) {
        const audio = new Audio(sound);
        audio.muted = true;
        audio.play();
      }
    },
    playAudio() {
      document.getElementById('episode-audio').muted = false;
      document.getElementById('episode-audio').play();
    },
    handleScroll(e) {
      console.log('user is scrolling');
      console.log(e);
    },
    getScreenCoordinates(obj) {
      const p = {};
      p.x = obj.offsetLeft;
      p.y = obj.offsetTop;
      while (obj.offsetParent) {
        p.x = p.x + obj.offsetParent.offsetLeft;
        p.y = p.y + obj.offsetParent.offsetTop;
        if (obj == document.getElementsByTagName("body")[0]) {
          break;
        } else {
          obj = obj.offsetParent;
        }
      }
      return p;
    }
  }
}
</script>
