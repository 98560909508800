import Vue from 'vue';
import App from './App.vue';
import KotonaruSekai from './kotonarusekai';

Vue.use(KotonaruSekai);

Vue.config.productionTip = false;
new Vue({
    render: h => h(App),
}).$mount('#app')
